// import logo from './logo.svg';
import './App.css';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

import Login from './pages/Login';
import Landing from './pages/Landing';
import Profile from './pages/Profile';
import Decks from './pages/Decks';
import CreateNewDeck from './pages/CreateNewDeck';

import Layout from './components/Layout';


function App() {

  const router = createBrowserRouter([
    {
      element: <Landing />,
      path: "/"
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      element: <Layout />,
      children: [
        {
          path: "/app/create",
          element: <CreateNewDeck />,
        },
        {
          path: "/app/decks",
          element: <Decks />,
        },
        {
          path: "/app/profile",
          element: <Profile />,
        },
        {
          path: "*",
          element: <Decks />,
        },
      ]
    },

  ])

  return (
      <RouterProvider router={router} />
  )
}

export default App;
