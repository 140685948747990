


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

import { Routes, Route, Navigate } from 'react-router-dom';



function Landing() {
    return (
            <Container component="main" maxWidth="md">
                <CssBaseline />

                <Box component="section" sx={{ p: 2, marginTop: 8, border: '1px dashed grey' }}>

                    <Typography> Landing page - slide-o-matic is awesome! </Typography>
                    <Link href="/login">Login</Link>

                </Box>

            </Container>
    )
}

export default Landing;
