import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';


import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";

import React, { useState, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';

// TODO load this from /config
const stripePublicKey = "pk_test_51OzCBDP0MMgnVGLe33VZsJA2MIMunIdTN62dfuAcaDL2aix39kt1xPBzt8CH2OHj2K4ZbBOFA1g2tLRcQJDMIjVJ00IqKEU5o2";
const stripe = await loadStripe(stripePublicKey);


function Profile() {
    const navigate = useNavigate();
    const buyCredits = () => {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            fetch("/api/stripe/create-checkout-session", {
                headers: {
                    'FirebaseIdToken': idToken
                }
            })
                .then((result) => { return result.json(); })
                .then((data) => {
                    console.log(data);
                    // Redirect to Stripe Checkout
                    return stripe.redirectToCheckout({ sessionId: data.sessionId })
                })
                .then((res) => {
                    console.log(res);
                });
        })
    }

    const [user, setUser] = useOutletContext()

    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
            fetch('/api/get_profile', {
                method: 'get',
                headers: {
                    'FirebaseIdToken': idToken
                }
            })
                .then(response => response.json())
                .then(json => setProfileData(json))
                .catch(error => console.error(error));

            console.log(profileData)
        }).catch(function (error) {
            // Handle error
            console.log(error)
        });
    }, []);

    return (
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box component="section" sx={{ p: 2, marginTop: 8, border: '1px dashed grey' }}>

                    <Typography> Your Profile </Typography>

                    {profileData ? <Typography>Credits available: {profileData["slide_deck_credits"]}</Typography> : 'Loading...'}


                    <Link onClick={buyCredits}>Purchase more credits</Link>

                </Box>

            </Container>
    )


}

export default Profile;
