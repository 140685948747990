import logo from '../logo.svg';
import '../App.css';

import { auth } from '../firebase';


import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'


// Note that FirebaseUI now needs to disable email enumeration protection
// See https://github.com/firebase/firebaseui-web/issues/1040 
// and https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection#disable


function Login() {
    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', {
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        signInSuccessUrl: '/app/profile'
        // Other config options...
    });


    return (
        <div className="Login">
        <div id="firebaseui-auth-container"></div>
        {/* <div id="loader">Loading...</div> */}
        </div>
    );
}

export default Login;
