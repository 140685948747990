// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyBgAL8SS0V-dhNmlXYzwb4r-N-cOMPSUyM",
    authDomain: "slide-o-matic-895df.firebaseapp.com",
    projectId: "slide-o-matic-895df",
    storageBucket: "slide-o-matic-895df.appspot.com",
    messagingSenderId: "1070730077877",
    appId: "1:1070730077877:web:32558b9ad1b796d8ce40fc",
    measurementId: "G-80LPN6PH8E"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;