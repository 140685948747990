import { Outlet } from "react-router-dom"
import Header from "./Header"
import React, { useState, useEffect } from 'react';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import Login from "../pages/Login";




export default function Layout() {

    const [loginState, setLoginState] = useState({
        isLoading: true,
        user: null
    })

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setLoginState({
                    isLoading: false,
                    user: user
                })
            } else {
                // User is signed out
                // ...
                console.log("user is logged out")
                setLoginState({
                    isLoading: false,
                    user: null
                })
            }
        });

    }, [])


    if (loginState.isLoading) {
        // TODO make this prettier
        return (
            <h1>Loading...</h1>
        )
    }

    if (loginState.user == null) {
        // no header
        return <Login />
    }
    else {

        return (
            <>
                <Header user={loginState.user} />
                <main>
                        <Outlet context={[loginState.user, setLoginState]} />
                </main>
            </>
        )
    }
}