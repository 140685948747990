


import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';


import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { PiMicrosoftPowerpointLogo } from "react-icons/pi";
import { PiFilePdf } from "react-icons/pi";


import { auth } from '../firebase';

import React, { useState, useEffect } from 'react';

import { getStorage, ref, getDownloadURL } from "firebase/storage";


function Decks() {

    const [slideDecks, setSlideDecks] = useState(null);

    useEffect(() => {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
            fetch('/api/get_slide_decks', {
                method: 'get',
                headers: {
                    // 'Accept': 'application/json, text/plain, */*',
                    // 'Content-Type': 'application/json',
                    'FirebaseIdToken': idToken
                }
            })
                .then(response => response.json())
                .then(json => setSlideDecks(json))
                .catch(error => console.error(error));

            // console.log(slideDecks)
        }).catch(function (error) {
            // Handle error
            console.log(error)
        });
    }, []);


    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };


    const downloadDeck = (deck_uuid, format) => {
        const storagePath = `decks/${auth.currentUser.uid}/${deck_uuid}.${format}`
        console.log("download ", storagePath)


        const storage = getStorage();
        getDownloadURL(ref(storage, storagePath))
            .then((url) => {
                openInNewTab(url)
            })
            .catch((error) => {
                // Handle any errors
                console.log(error)
            });
    }






    if (slideDecks == null) {
        return (
            <h1>Loading</h1>
        )
    }

    const rows = Object.values(slideDecks)

    const DECK_STATUS_REQUESTED = "requested"
    const DECK_STATUS_FINISHED = "finished"


    const columns = [
        { field: 'create_date', headerName: 'Created on', width: 200 },
        // { field: 'deck_uuid', headerName: 'UUID', minWidth: 100, flex: 1 },
        { field: 'prompt', headerName: 'Your prompt', minWidth: 100, flex: 1 },
        // { field: 'status', headerName: 'Status', minWidth: 100, flex: 1 },
        {
            field: 'status', headerName: 'Status', width: 110, renderCell: (params) => {
                if (params.row.status === DECK_STATUS_FINISHED) {
                    return "Ready"
                } else {
                    return "Creating..."
                }
            }
        },
        {
            field: 'pdf_link', headerName: 'PDF', width: 70, renderCell: (params) => {
                const download_url = params.row.deck_uuid
                const isDisabled = params.row.status != DECK_STATUS_FINISHED
                // TODO make these colors real
                const color = isDisabled ? 'gray' : 'red'
                return (
                    <IconButton disabled={isDisabled} onClick={() => {
                        downloadDeck(params.row.deck_uuid, "pdf")
                    }}><PiFilePdf color={color} /></IconButton>
                )
            }
        },
        {
            field: 'ppt_link', headerName: 'PPTX', width: 70, renderCell: (params) => {
                const download_url = params.deck_uuid
                const isDisabled = params.row.status != DECK_STATUS_FINISHED
                const color = isDisabled ? 'gray' : 'orange'
                return (
                    <IconButton disabled={isDisabled} onClick={() => {
                        downloadDeck(params.row.deck_uuid, "pptx")
                    }}><PiMicrosoftPowerpointLogo color={color} /></IconButton>
                )
            }
        },
    ]


    return (
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box component="section" sx={{ p: 2, marginTop: 8, border: '0px dashed grey' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row.deck_uuid}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 50 },
                            },
                            sorting: {
                                sortModel: [{ field: 'create_date', sort: 'desc' }],
                            },
                        }}
                    />

                </Box>
            </Container>
    )


}

export default Decks;
