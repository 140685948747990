


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { useState } from 'react';


import { Routes, Route, Navigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";



function CreateNewDeck() {

    const [prompt, setPrompt] = useState('');
    let navigate = useNavigate();


    const handleRequestSuccess = (json) => {
        const navigate_url = `/app/decks#${json.deck_uuid}`
        navigate(navigate_url);
    }

    const requestNewSlide = (e) => {
        e.preventDefault()

        auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
            fetch('/api/request_new_deck', {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'FirebaseIdToken': idToken
                },
                body: JSON.stringify({
                    "prompt": prompt
                })
            })
                .then(response => response.json())
                .then(json => handleRequestSuccess(json))

                // TODO handle errors properly
                .catch(error => console.error(error));

        }).catch(function (error) {
            // Handle error
            console.log(error)
        });


    }

    return (
            <Container component="main" maxWidth="md">
                <CssBaseline />

                <Box component="section" sx={{ p: 2, marginTop: 8, border: '1px dashed grey' }}>
                    <Typography> Create a new slide deck </Typography>

                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={requestNewSlide}
                    >
                        <TextField
                            id="outlined-textarea"
                            label="Prompt"
                            placeholder="What is your slide deck about?"
                            multiline
                            fullWidth
                            size='large'
                            rows={4}
                            onChange={(e) => setPrompt(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Generate slides!
                        </Button>


                    </Box>
                </Box>

            </Container>
    )


}

export default CreateNewDeck;
